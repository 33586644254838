<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <van-tabs v-model="active" @change="tabChange" color="#1890ff" title-active-color="#1890ff">
        <van-tab name="1" title="销售个人行为">
          <template #title><van-icon name="todo-list-o" /> 我的活动数据</template>
          <personal></personal>
        </van-tab>

        <template v-if="userPermission < 2">
          <van-tab name="2" title="员工参与数据">
            <pageList></pageList>
          </van-tab>
        </template>
        
      </van-tabs>
    </div>
  </div>
</template>

<script>
import personal from '../participate/components/personal'
import pageList from '../participate/components/list'
import { mapGetters } from 'vuex'
export default {
  components: {
    personal,
    pageList
  },
  data() {
    return {
      active: '1',
    }
  },
  watch: {
    // active(e){
    //   const that = this
    //   if(e == '2'){
    //     setTimeout(function () {
    //       that.categoryCategory()
    //     },1)
    //   }
    // }
  },
  computed:{
		...mapGetters([
			'userPermission'
		])
	},
  created () {
    console.log(this.userPermission)
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    tabChange (name, title) {
    },
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tabs__wrap {
  height: 54px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
  padding-top: 10px;
}

/deep/ .van-tabs__nav {
  background: #f4f5f7;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/.van-action-sheet {
  max-height: 98%;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    min-height: 92%;
    // padding-top: 5px;

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }
      }
     
    }
  }
}
.summary {
  text-align:center; 
  margin-bottom: 0px;
  background-color: #fff;
  padding: 12px 12px 0;
  
  &-item {
    display: flex;
    flex-flow: column;
    padding-bottom: 8px;
    padding-top: 10px;
    margin-bottom: 12px;
    background-color: #f2f3f7;
    border-radius: 8px;

    span {
      color: #1989fa;
      font-size: 18px;
      // font-weight: 600;

      div {
        display: inline-block;
      }
    }
  }
}
.activity-list {
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;

  &-title {
    padding: 0 12px;
    // color: #1989fa;
    // font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    
    div {
      margin-top: 5px;
      font-size: 12px;
      color: #999;
      font-weight: 400;
    }
  }
  &-summary {
    text-align:center; 
    padding: 12px 0;
    background: #f2f3f7;
    border-radius: 8px;
    margin: 6px 12px 20px;

    .van-col {
      display: flex;
      flex-flow: column;
      padding-bottom: 6px;
      padding-top: 6px;
      font-size: 12px;

      span {
        color: #1989fa;
        font-size: 18px;
        // font-weight: 600;
      }
    }
  }
}
</style>
