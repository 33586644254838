<template>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >

        <van-cell-group inset :border="false" v-for="(item, index) in list" :key="index" class="list-item">
          <van-cell :border="false">
            <template #title v-if="item.contact">
              <span class="custom-title list-item-title">
                <van-image
                  width="36"
                  height="36"
                  fit="cover"
                  round
                  :src="item.contact.avatar"
                />
                <div>
                  {{ item.contact.name }}
                  <div class="time">{{ item.created_at }}</div>
                </div>
              </span>
            </template>
            <template slot="label">
              <div class="list-item-summary">
                <van-row gutter="12">
                  <!-- <van-col span="8">分享次数<span>{{ item.shares }}</span></van-col>
                  <van-col span="8">分享受众<span>{{ item.unique_view }}</span></van-col>
                  <van-col span="8">裂变人数<span>{{ item.fission_unique_view }}</span></van-col>
                  <van-col span="8">裂变留资<span>{{ item.fission_info_number }}</span></van-col>
                  <van-col span="8">裂变层数<span>{{ item.level }}</span></van-col> -->
                  <!-- <van-col span="8"></van-col> -->
                  <van-col span="8">分享次数<span>{{ item.shares }}</span></van-col>
                  <van-col span="8">分享受众<span>{{ item.unique_view }}</span></van-col>
                  <van-col span="8">直接留资<span>{{ item.info_number }}</span></van-col>
                  <van-col span="8">访问次数<span>{{ item.views }}</span></van-col>
                  <van-col span="8">浏览总时长<span>{{ item.total_time }}/s</span></van-col>
                  <van-col span="8">裂变层数<span>{{ item.level }}</span></van-col>
                  <van-col span="8"></van-col>
                  <van-col span="8"></van-col>
                </van-row>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('@/assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
</template>
<script>
import { joinMemberList } from '@/api/participate'
export default {
  data() {
    return {
      article_spread_id: '',
      serchValue: '',
      loading: false,
      finished: false,
      list: [
      ],
      page: 1,
      perPage: 15
    }
  },
  created () {
    this.article_spread_id = this.$route.query.id
  },
 
  methods: {
    checkDetail (item) {
      pushUser(item)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      joinMemberList({
        article_spread_id: this.article_spread_id,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">

.content {
  height: 100%;
  background: #f4f5f7;

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 12px;

    .list-item {

      /deep/.van-cell__label {
        margin-top: 8px;
      }

      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      /deep/.van-cell {
        background-color: #fff;
        padding: 10px;

        .van-cell__value {
          flex: unset;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      .time {
        font-size: 12px;
        color: #888;
        line-height: 18px;
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        font-size: 28px;
        color: #1989fa;
      }

      &-summary {
        text-align:center; 
        padding: 10px 0 0px;
        background: #f9f9f9;
        border-radius: 6px;
        // margin: 6px 12px 20px;

        .van-col {
          display: flex;
          flex-flow: column;
          padding-bottom: 6px;
          padding-top: 0px;
          font-size: 12px;
          color: #999;

          span {
            color: #1989fa;
            font-size: 16px;
            // font-weight: 600;
          }
        }
      }

    }      
  }
</style>
