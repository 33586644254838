<template>
    <div class="couponPage">
        <div class="couponPage-main">
            <div class="couponPage-main-box" v-if="showCoupon">
                <div class="couponPage-main-box-head">
                    <div>{{ detail.coupon.coupon_name }}</div>
                    <span>【{{ detail.coupon.type_text }}】</span>
                </div>
                <div class="couponPage-main-box-body">
                    <van-cell-group>
                        <van-cell title="状态：">
                            <template #right-icon>
                                <van-tag :type="detail.status == 1 ? 'success' : detail.status == 2 ? 'warning': 'danger'">{{ detail.status_text }}</van-tag>
                            </template>
                        </van-cell>
                        <van-cell title="领取日期：" :value="detail.created_at" />
                        <van-cell title="有效期到：" :value="detail.expire_time" />
                    </van-cell-group>
                    <div class="desc" v-html="detail.coupon.desc">
                    </div>
                     <!-- v-if="detail.status == 1"  v-if="detail.coupon.desc"-->
                    <div class="btn">
                        <van-button type="info" block  @click="writeCouponHland">核销</van-button>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="couponPage-main-box" v-if="showProduct">
                <div class="couponPage-main-box-body productMain">
                    <van-cell-group inset style="margin-bottom: 40px">
                        <van-cell title="订单号">
                        <div slot="default">
                            {{detail.order_no}}
                        </div>
                        </van-cell>
                        <van-cell title="下单时间" :value="detail.created_at" />
                        <van-cell title="类型" :value="detail.type_text" />
                        <van-cell title="状态" :value="detail.status_text" />
                        <van-cell title="提货人" :value="detail.real_name" />
                        <van-cell title="电话" :value="detail.user_phone" />
                    </van-cell-group>
                    <van-card
                        num="1"
                        :price="detail.pay_price"
                        :desc="detail.product.store_info"
                        :title="detail.product.store_name"
                        :thumb="detail.product.image"
                        />
                </div>
                <div class="btn">
                    <van-button type="info" block  @click="writeCouponHland">核销</van-button>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import { checkCoupon, writeCoupon, checkShop, shopWriteOff } from '@/api/coupon'
import { Toast, Card, Cell, CellGroup } from 'vant'
import Vue from 'vue';
Vue.use(Card);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
    data () {
        return {
            sw: '',
            showProduct: false,
            showCoupon: false,
            detail: {
            }
        }
    },
    created () {
        console.log(this.$route.query.s)
        this.sw = JSON.parse(this.$route.query.s)
        // this.detail = this.res.data
        this.couponData()
    },
    methods: {
        couponData () {
            switch (this.sw.type) {
                case 'coupon':
                    checkCoupon({write_off_code: this.sw.value}).then(res => {
                        this.detail = res.data
                        this.showCoupon = true
                    })
                    break
                case 'product':
                    checkShop({write_off_code: this.sw.value}).then(res => {
                        this.detail = res.data
                        this.showProduct = true
                        console.log(this.detail)
                    })
                    break
            }
            
        },
        writeCouponHland (type) {
            switch (this.sw.type) {
                case 'coupon':
                    writeCoupon({write_off_code: this.sw.value}).then(res => {
                        if(res.code == 200){
                            Toast.success('核销成功');
                        }else{
                            Toast.fail(res.msg)
                        }
                        this.couponData()
                    })
                    break
                case 'product':
                   shopWriteOff({write_off_code: this.sw.value}).then(res => {
                        if(res.code == 200){
                            Toast.success('核销成功');
                        }else{
                            Toast.fail(res.msg)
                        }
                        this.couponData()
                    })
                    break
            }
            
        }
    }
}
</script>
<style scoped lang="less">
.couponPage {
    // height: 100vh;
    background: linear-gradient(180deg,#3974c7,#0a42c3 55%,#2b63cd);
    
    &-main {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-box {
            position: relative;
            min-height: 90vh;
            width: 90%;
            margin: 5vh 0;
            // -webkit-mask: radial-gradient(circle at 0 0, transparent 20px, #fff 21px), 
            //     radial-gradient(circle at right 0, transparent 20px, #fff 21px), 
            //     radial-gradient(circle at 0 100%, transparent 20px, #fff 21px), 
            //     radial-gradient(circle at right 100%, transparent 20px, #fff 21px); 
            // -webkit-mask-composite: source-in;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;

            &-head {
                background: #f5f5f5;
                padding: 15px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;

                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .btn {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 20px;

                .van-button {
                    font-size: 16px;
                }
            }
            .desc {
                position: absolute;
                top: 200px;
                bottom: 90px;
                left: 0;
                right: 0;
                overflow-y: auto;
                font-size: 12px;
                padding: 15px;
                // background-color: #f9f9f9;
                
                img {
                    max-width: 100%;
                }
            }
        }

        .productMain {
            // padding: 20px;

            /deep/.van-cell-group--inset {
                margin: 0;
                background-color: #f9f9f9;
            }

            /deep/.van-cell-group__title--inset {
                padding: 16px 16px 8px 16px;
            }

            /deep/.van-card {
                // background: #fff;
                padding: 10px;
            }
        }
    }
}
</style>
