<template>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >

        <van-cell-group inset :border="false" v-for="(item, index) in list" :key="index" class="list-item">
          <van-cell :border="false" style="align-items: center;">
            <template #title>
              <span class="custom-title list-item-title">
                <div>
                  <van-icon name="user-o" /> {{ item.name }}
                </div>
              </span>
            </template>
            <template>
              <div class="list-item-value"><a :href="'tel:' + item.phone"><van-icon name="phone-circle-o" /></a></div>
            </template>
            <template slot="label">
              <div class="time"><van-icon name="phone-o" /> 手机：{{ item.phone }} <van-tag type="primary" @click="copyData(item.phone)">复制</van-tag></div>
              <div class="time"><van-icon name="location-o" /> 地区：{{ item.province + item.city + item.district }}</div>
              <div class="time"><van-icon name="underway-o" /> 留资时间：{{ item.created_at }}</div>
              <div class="time"><van-icon name="goods-collect-o" /> 购车意向：{{ item.intended_model }}</div>
              <div class="time"><van-icon name="todo-list-o" /> 购车时间：{{ item.plan_time }}</div>
              <div class="time"><van-icon name="bulb-o" /> 方便接听：{{ item.convenience_time }}</div>
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('@/assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
</template>
<script>
import { joinLeaveInfoList } from '@/api/participate'
import { setClipboardData } from '@/utils/wxCodeAuth'
import { Toast } from 'vant'
export default {
  data() {
    return {
      article_spread_id: '',
      serchValue: '',
      loading: false,
      finished: false,
      list: [
      ],
      page: 1,
      perPage: 15
    }
  },
  created () {
    this.article_spread_id = this.$route.query.id
  },
 
  methods: {
    async copyData (value) {
      await setClipboardData(value)
    },
    checkDetail (item) {
      pushUser(item)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      joinLeaveInfoList({
        article_spread_id: this.article_spread_id,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">

.content {
  height: 100%;
  background: #f4f5f7;

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 12px;

    .list-item {
      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      /deep/.van-cell {
        background-color: #fff;

        .van-cell__value {
          flex: unset;
        }
      }

      &-title {
        display: flex;
        align-items: center;
      }

      .time {
        font-size: 12px;
        color: #888;
        line-height: 18px;
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        font-size: 28px;
        color: #1989fa;
      }
    }      
  }
</style>
