<template>
    <van-pull-refresh v-model="refreshLoading" @refresh="refreshData" class="refreshBox">
      <div class="title"><van-icon name="todo-list-o" /> 智慧传播活动销售个人行为数据</div>
      <van-row gutter="12" class="summary" v-if="show">
        <van-col span="8">
          <div class="summary-item">
            活动次数<span>{{ list.length }}</span>
          </div>
        </van-col>
        <van-col span="8"><div class="summary-item">分享次数<span>{{ totalData.shares }}</span></div></van-col>
        <van-col span="8"><div class="summary-item">分享受众<span>{{ totalData.unique_view }}</span></div></van-col>
        <van-col span="8"><div class="summary-item">直接留资<span>{{ totalData.info_number }}</span></div></van-col>
        <van-col span="8"><div class="summary-item">裂变人数<span>{{ totalData.fission_unique_view }}</span></div></van-col>
        <van-col span="8"><div class="summary-item">裂变留资<span>{{ totalData.fission_info_number }}</span></div></van-col>
        <van-col span="8"><div class="summary-item">裂变层数<span>{{ totalData.level }}</span></div></van-col>
        <van-col span="8"></van-col>
        <van-col span="8"></van-col>
      </van-row>
      <van-divider style="margin-bottom: 20px; margin-top: 30px" :style="{ borderColor: '#ddd' }">参与活动列表</van-divider>
      <van-list 
        v-model="listLoading" 
        :finished="listFinished" 
        :finished-text="list.length > 0 ? '没有更多了' : ''" 
        @load="getList"
      >
        <div v-for="(item, index) in list" :key="index" class="activity-list">
          <div class="activity-list-title">
            <van-icon name="newspaper-o" /> {{ item.article.title }}
            <!-- <div>开始时间：{{ getLocalTime(item.starttime) }}</div> -->
          </div>
          <div class="activity-list-summary">
            <van-row gutter="12">
              <van-col span="8">分享次数<span>{{ item.shares }}</span></van-col>
              <van-col span="8">分享受众<span>{{ item.unique_view }}</span></van-col>
              <van-col span="8">裂变人数<span>{{ item.fission_unique_view }}</span></van-col>
              <van-col span="8">裂变留资<span>{{ item.fission_info_number }}</span></van-col>
              <van-col span="8">裂变层数<span>{{ item.level }}</span></van-col>
              <van-col span="8"></van-col>
            </van-row>
          </div>
          <van-cell title="查看留资" value="" is-link :to="'/participate/joinLeaveInfoList?id=' + item.article_spread_id"/>
          <van-cell title="好友参与情况" value="" is-link :to="'/participate/joinMemberList?id=' + item.article_spread_id"/>
        </div>
      </van-list>
      <van-empty description="暂无数据" v-if="!listLoading && show && list.length == 0"/>
    </van-pull-refresh>
</template>
<script>
import { participateIndex, joinTotal, joinList } from '@/api/participate'
export default {
  data () {
    return {
      show: false,
      totalData: {},
      list: [],
      refreshLoading: false,
      listLoading: false,
      listFinished: false,
      page: 1
    }
  },
  mounted () {
    // participateIndex().then(res => {
    //   this.res = res.data
    //   this.show = true
    // })
    this.getTotal()
  },
  methods: {
    getTotal () {
      joinTotal().then(res => {
        console.log(res)
        this.totalData = res.data.total_data
        this.refreshLoading = false
        this.show = true
      })
    },
    getList () {
      joinList({
        contactId: this.contactId,
        page: this.page
      }).then(res => {
        this.list = this.list.concat(res.data.lists.data)
        if (this.list.length == res.data.lists.total) {
          this.listFinished = true
        } else {
          this.page++
        }
        this.listLoading = false
      })
    },
    refreshData () {
      this.refreshLoading = true
      this.page = 1
      this.listLoading = true
      this.list = []
      this.getTotal()
      this.getList()
    },
    getLocalTime (nS) {  
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
    }
  }
}
</script>
<style lang="less" scoped>
.refreshBox {
  background-color: #F1f2f5;
}
.van-list {
  // background-color: #fff;
}
.title {
    padding: 15px 20px;
    background: #fff;
    border-bottom: 1px solid #eee;
    font-size: 16px;
}
.summary {
  text-align:center; 
  margin-bottom: 0px;
  background-color: #fff;
  padding: 12px 12px 0;
  
  &-item {
    display: flex;
    flex-flow: column;
    padding-bottom: 8px;
    padding-top: 10px;
    margin-bottom: 12px;
    background-color: #f2f3f7;
    border-radius: 8px;

    span {
      color: #1989fa;
      font-size: 18px;
      // font-weight: 600;

      div {
        display: inline-block;
      }
    }
  }
}
.activity-list {
  // border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  margin: 12px 10px;

  &-title {
    // padding: 0 12px;
    // color: #1989fa;
    // font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    
    div {
      margin-top: 5px;
      font-size: 12px;
      color: #999;
      font-weight: 400;
    }
  }
  &-summary {
    text-align:center; 
    padding: 10px 0 4px;
    background: #f9f9f9;
    border-radius: 6px;
    // margin: 6px 12px 20px;

    .van-col {
      display: flex;
      flex-flow: column;
      padding-bottom: 6px;
      padding-top: 0px;
      font-size: 12px;
      color: #999;

      span {
        color: #1989fa;
        font-size: 16px;
        // font-weight: 600;
      }
    }
  }

  /deep/.van-cell__title {
    font-size: 12px;
    opacity: .6;
  }
  /deep/.van-cell__right-icon {
    opacity: .6;
  }
}
</style>>

