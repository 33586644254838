<template>
  <div class="index">
    <van-search
      v-model="searchValue"
      placeholder="请输入搜索关键词"
      show-action
      action-text="全部"
      @search="onSearch"
      @cancel="onCancel"
    />
    <van-pull-refresh v-model="refreshLoading" @refresh="refreshData">
      <van-list 
        v-model="listLoading" 
        :finished="listFinished" 
        :finished-text="list.length > 0 ? '没有更多了' : ''" 
        @load="getList"
        class="activity"
      >
        <div v-for="(item, index) in list" :key="index" class="activity-list" @click="pushData(item.id, item.title)">
          <div>{{ item.title }}</div>
          <div style="display: flex; align-items: center;">
            <div class="activity-list-desc">
              <img :src="item.thumbnail" alt="">
              <div>{{ item.desc }}</div>
            </div>
            <div class="activity-list-check"><van-icon name="arrow" /></div>
          </div>
          
        </div>
      </van-list>
      <van-empty description="暂无数据" v-if="!listLoading && list.length == 0"/>
    </van-pull-refresh>
  </div>
</template>
<script>
import { articleSpread } from '@/api/participate'
export default {
  data () {
    return {
      list: [],
      refreshLoading: false,
      listLoading: false,
      listFinished: false,
      page: 1,
      searchValue: ''
    }
  },
  mounted () {
    // participateIndex().then(res => {
    //   this.res = res.data
    //   this.show = true
    // })
  },
  methods: {
    pushData (id, title) {
      this.$router.push({
        path: "/participateData",
        query:{
          id: id,
          title: title
        }
      })
    },
    onSearch () {
      console.log(this.searchValue)
      this.page = 1
      this.list = []
      this.getList()
    },
    onCancel () {
      this.searchValue = ''
      this.page = 1
      this.list = []
      this.getList()
    },
    getList () {
      this.listLoading = true
      let param = {
        page: this.page,
      }
      if (this.searchValue) {
        param.title = this.searchValue
      }
      articleSpread(param).then(res => {
        this.list = this.list.concat(res.data.lists.data)
        if (this.list.length == res.data.lists.total) {
          this.listFinished = true
        } else {
          this.page++
        }
        this.listLoading = false
        this.refreshLoading = false
      })
    },
    refreshData () {
      this.refreshLoading = true
      this.page = 1
      this.listLoading = true
      this.list = []
      this.getList()
    },
    userChange(data, info) {
      this.selectedEmployee = data
      this.page = 1
      this.listLoading = true
      this.list = []
      this.getList()
      // this.StandbyStaff = data.map(i=>i.name)
    },
  }
}
</script>
<style lang="less" scoped>
.index {
  background: #fff;
}
.activity {
  
  .activity-list {
    background: #fff;
    padding: 12px 12px 18px;
    border-bottom: .5px solid #f1f1f1;

    &-desc {
      flex: 1;
      display: flex;
      align-items: center;
      background: #f8f8f8;
      padding: 10px;
      margin-top: 6px;
      border-radius: 6px;
      color: #999;
      font-size: 12px;

      img {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }

      >div {
        overflow: hidden;//溢出隐藏
        white-space: nowrap;//禁止换行
        text-overflow: ellipsis;//...
      }
    }

    &-check {
      margin-left: 10px;
    }
  }
}


</style>>

