import request from "../plugins/axios";

// 查看核销优惠券详情
export function checkCoupon(params) {
    return request({
        url: '/coupon/writeOffShow',
        method: 'POST',
        params
    })
}

// 确认核销
export function writeCoupon(params) {
    return request({
        url: '/coupon/writeOff',
        method: 'POST',
        params
    })
}

// 确认核销
export function checkShop(params) {
    return request({
        url: '/shop/writeOffShow',
        method: 'POST',
        params
    })
}


// 商品核销
export function shopWriteOff(params) {
    return request({
        url: '/shop/writeOff',
        method: 'POST',
        params
    })
}
